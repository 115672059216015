import './vue_overrides';
import { addAppealModalPopupListener } from 'jh/appeal';
import { initCaptcha } from 'jh/captcha/init_captcha';
import { initUserTracking, initDefaultTrackers } from 'jh/tracking';

function deferredInitialisation() {
  initDefaultTrackers();
}

if (window.posthog) {
  // set window.snowplow to a noop function, avoid upstream snowplow bootstrapping.
  window.snowplow = () => undefined;
  window.snowplowOptions = {};
  initUserTracking();

  requestIdleCallback(deferredInitialisation);
}

addAppealModalPopupListener();

// init tencent captcha if there exists a .js-captcha stub element in HTML
initCaptcha(gon.tencent_captcha_app_id);
