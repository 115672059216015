<script>
import { GlLoadingIcon, GlSafeHtmlDirective } from '@gitlab/ui';
import { s__ } from '~/locale';
import createFlash from '~/flash';
import { initTencentRecaptchaScript } from 'jh/captcha/init_recaptcha_script';
import defaultTanuki from 'jh_images/captcha/tanuki-verify.svg';
import verfiedTanuki from 'jh_images/captcha/tanuki-verified.svg';
import { READY, RESOLVING, DONE, i18n } from 'jh/captcha/constants';

export default {
  defaultTanuki,
  verfiedTanuki,
  i18n,
  statusMap: {
    READY,
    RESOLVING,
    DONE,
  },
  components: {
    GlLoadingIcon,
  },
  directives: {
    SafeHtml: GlSafeHtmlDirective,
  },
  props: {
    captchaSiteKey: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      captchaResponse: '',
      status: READY,
      captcha: null,
    };
  },
  computed: {
    buttonText() {
      return this.$options.i18n[this.status];
    },
  },
  mounted() {
    initTencentRecaptchaScript()
      .then((TencentCaptcha) => {
        this.captcha = TencentCaptcha;
      })
      .catch((e) => {
        createFlash({
          message: s__('Captcha|There was an error with the Tencent Captcha. Please try again.'),
        });
        this.$emit('loadError', e);
        throw e;
      });
  },
  methods: {
    emitReceivedCaptchaResponse(captchaResponse) {
      this.$emit('receivedCaptchaResponse', captchaResponse);
    },
    startResolving() {
      if (this.status !== this.$options.statusMap.READY) {
        return;
      }

      this.status = this.$options.statusMap.RESOLVING;
      const TencentCaptcha = this.captcha;
      const captcha = new TencentCaptcha(this.captchaSiteKey, (res) => {
        if (res.ret === 0) {
          const captchaResponse = btoa(
            JSON.stringify({
              rand_str: res.randstr,
              ticket: res.ticket,
            }),
          );
          this.status = this.$options.statusMap.DONE;
          this.captchaResponse = captchaResponse;
          this.emitReceivedCaptchaResponse(captchaResponse);
        } else if (res.ret === 2) {
          this.status = this.$options.statusMap.READY;
        }
      });
      captcha.show();
    },
  },
};
</script>
<template>
  <div
    class="tencent-captcha gl-display-flex gl-align-items-center gl-bg-white gl-hover-bg-gray-50 gl-rounded-base gl-font-base gl-line-height-normal gl-text-gray-900 gl-font-regular gl-inset-border-1-gray-200"
    :class="{ 'gl-cursor-pointer': status === $options.statusMap.READY }"
    @click="startResolving"
  >
    <input type="hidden" name="tencent_captcha_response" :value="captchaResponse" />
    <transition name="fade" mode="out-in">
      <div
        v-if="status === $options.statusMap.READY"
        :key="$options.statusMap.READY"
        class="gl-display-flex gl-align-items-center"
      >
        <div
          v-safe-html="$options.defaultTanuki"
          class="gl-ml-6 gl-mr-4 gl-w-6 gl-display-flex gl-align-items-center"
        ></div>
        <span>{{ buttonText }}</span>
      </div>
      <div
        v-if="status === $options.statusMap.RESOLVING"
        :key="$options.statusMap.RESOLVING"
        class="gl-display-flex gl-align-items-center"
      >
        <div class="gl-ml-6 gl-mr-4 gl-w-6 gl-display-flex gl-align-items-center">
          <gl-loading-icon />
        </div>
        <span>{{ buttonText }}</span>
      </div>
      <div
        v-if="status === $options.statusMap.DONE"
        :key="$options.statusMap.DONE"
        class="gl-display-flex gl-align-items-center"
      >
        <div
          v-safe-html="$options.verfiedTanuki"
          class="gl-ml-6 gl-mr-4 gl-w-6 gl-display-flex gl-align-items-center"
        ></div>
        <span>{{ buttonText }}</span>
      </div>
    </transition>
  </div>
</template>
<style scoped>
.tencent-captcha {
  width: 300px;
  height: 74px;
}
</style>
