import Vue from 'vue';
import Captcha from 'jh/captcha/captcha.vue';

/**
 * Init a Captcha inline the form with provided captchaSiteKey.
 *
 * @param captchaSiteKey
 */
export function initCaptcha(captchaSiteKey) {
  const captchaElement = document.querySelector('.js-captcha');

  if (!captchaElement) {
    return;
  }

  // eslint-disable-next-line no-new
  new Vue({
    el: captchaElement,
    render: (createElement) => {
      return createElement(Captcha, {
        props: {
          captchaSiteKey,
        },
      });
    },
  });
}
