var render = function () {
var _obj, _obj$1;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"observe-visibility",rawName:"v-observe-visibility",value:(_vm.handleVisibilityChange),expression:"handleVisibilityChange"}],staticClass:"vue-recycle-scroller",class:( _obj = {
    ready: _vm.ready,
    'page-mode': _vm.pageMode
  }, _obj[("direction-" + _vm.direction)] = true, _obj ),on:{"&scroll":function($event){return _vm.handleScroll.apply(null, arguments)}}},[(_vm.$slots.before)?_c('div',{staticClass:"vue-recycle-scroller__slot"},[_vm._t("before")],2):_vm._e(),_vm._v(" "),_c('div',{ref:"wrapper",staticClass:"vue-recycle-scroller__item-wrapper",style:(( _obj$1 = {}, _obj$1[_vm.direction === 'vertical' ? 'minHeight' : 'minWidth'] = _vm.totalSize + 'px', _obj$1 ))},_vm._l((_vm.pool),function(view){return _c('div',{key:view.nr.id,staticClass:"vue-recycle-scroller__item-view",class:{ hover: _vm.hoverKey === view.nr.key },style:(_vm.ready ? {
        transform: _vm.useTransform ? ("translate" + (_vm.direction === 'vertical' ? 'Y' : 'X') + "(" + (view.position) + "px)") : null,
        top: !_vm.useTransform && _vm.direction === 'vertical' ? ((view.position) + "px") : null,
        left: !_vm.useTransform && _vm.direction !== 'vertical' ? ((view.position) + "px") : null,
      } : null),on:{"mouseenter":function($event){_vm.hoverKey = view.nr.key},"mouseleave":function($event){_vm.hoverKey = null}}},[_vm._t("default",null,{"item":view.item,"index":view.nr.index,"active":view.nr.used})],2)}),0),_vm._v(" "),(_vm.$slots.after)?_c('div',{staticClass:"vue-recycle-scroller__slot"},[_vm._t("after")],2):_vm._e(),_vm._v(" "),_c('ResizeObserver',{on:{"notify":_vm.handleResize}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }