var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('button',{staticClass:"gl-display-flex gl-align-items-center gl-justify-content-center gl-font-sm",class:{
    'btn-transparent comment-indicator': _vm.isNewNote,
    'js-image-badge design-note-pin': !_vm.isNewNote,
    resolved: _vm.isResolved,
    inactive: _vm.isInactive,
    draft: _vm.isDraft,
    'on-image': _vm.isOnImage,
    'gl-absolute': _vm.position,
    small: _vm.size === 'sm',
  },style:(_vm.position),attrs:{"aria-label":_vm.pinLabel,"type":"button"},on:{"mousedown":function($event){return _vm.$emit('mousedown', $event)},"mouseup":function($event){return _vm.$emit('mouseup', $event)},"click":function($event){return _vm.$emit('click', $event)}}},[(_vm.isNewNote)?_c('gl-icon',{attrs:{"name":"image-comment-dark","size":24}}):[_vm._v("\n    "+_vm._s(_vm.label)+"\n  ")]],2)}
var staticRenderFns = []

export { render, staticRenderFns }