var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.showCommentToolBar)?_c('div',{staticClass:"comment-toolbar clearfix"},[_c('div',{staticClass:"toolbar-text"},[(!_vm.hasQuickActionsDocsPath && _vm.markdownDocsPath)?[_c('gl-sprintf',{attrs:{"message":_vm.s__('MarkdownToolbar|Supports %{markdownDocsLinkStart}Markdown%{markdownDocsLinkEnd}')},scopedSlots:_vm._u([{key:"markdownDocsLink",fn:function(ref){
var content = ref.content;
return [_c('gl-link',{attrs:{"href":_vm.markdownDocsPath,"target":"_blank"}},[_vm._v(_vm._s(content))])]}}],null,false,2510066785)})]:_vm._e(),_vm._v(" "),(_vm.hasQuickActionsDocsPath && _vm.markdownDocsPath)?[_c('gl-sprintf',{attrs:{"message":_vm.s__(
            'NoteToolbar|Supports %{markdownDocsLinkStart}Markdown%{markdownDocsLinkEnd}. For %{quickActionsDocsLinkStart}quick actions%{quickActionsDocsLinkEnd}, type %{keyboardStart}/%{keyboardEnd}.'
          )},scopedSlots:_vm._u([{key:"markdownDocsLink",fn:function(ref){
          var content = ref.content;
return [_c('gl-link',{attrs:{"href":_vm.markdownDocsPath,"target":"_blank"}},[_vm._v(_vm._s(content))])]}},{key:"keyboard",fn:function(ref){
          var content = ref.content;
return [_c('kbd',[_vm._v(_vm._s(content))])]}},{key:"quickActionsDocsLink",fn:function(ref){
          var content = ref.content;
return [_c('gl-link',{attrs:{"href":_vm.quickActionsDocsPath,"target":"_blank"}},[_vm._v(_vm._s(content))])]}}],null,false,1415694541)})]:_vm._e()],2),_vm._v(" "),(_vm.canAttachFile)?_c('span',{staticClass:"uploading-container gl-line-height-32"},[_c('span',{staticClass:"uploading-progress-container hide"},[_c('gl-icon',{attrs:{"name":"paperclip"}}),_vm._v(" "),_c('span',{staticClass:"attaching-file-message"}),_vm._v(" "),_c('span',{staticClass:"uploading-progress"},[_vm._v("0%")]),_vm._v(" "),_c('gl-loading-icon',{attrs:{"size":"sm","inline":""}})],1),_vm._v(" "),_c('span',{staticClass:"uploading-error-container hide"},[_c('span',{staticClass:"uploading-error-icon"},[_c('gl-icon',{attrs:{"name":"paperclip"}})],1),_vm._v(" "),_c('span',{staticClass:"uploading-error-message"}),_vm._v(" "),_c('gl-sprintf',{attrs:{"message":_vm.__(
            '%{retryButtonStart}Try again%{retryButtonEnd} or %{newFileButtonStart}attach a new file%{newFileButtonEnd}.'
          )},scopedSlots:_vm._u([{key:"retryButton",fn:function(ref){
          var content = ref.content;
return [_c('gl-button',{staticClass:"retry-uploading-link gl-vertical-align-baseline",attrs:{"variant":"link","category":"primary"}},[_vm._v("\n            "+_vm._s(content)+"\n          ")])]}},{key:"newFileButton",fn:function(ref){
          var content = ref.content;
return [_c('gl-button',{staticClass:"markdown-selector attach-new-file gl-vertical-align-baseline",attrs:{"variant":"link","category":"primary"}},[_vm._v("\n            "+_vm._s(content)+"\n          ")])]}}],null,false,3180960446)})],1),_vm._v(" "),_c('gl-button',{staticClass:"button-cancel-uploading-files gl-vertical-align-baseline hide",attrs:{"variant":"link","category":"primary"}},[_vm._v("\n      "+_vm._s(_vm.__('Cancel'))+"\n    ")])],1):_vm._e()]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }