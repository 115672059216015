import { s__ } from '~/locale';

export const READY = 'READY';
export const RESOLVING = 'RESOLVING';
export const DONE = 'DONE';

export const i18n = {
  [READY]: s__('Captcha|Please click here to finish the verification'),
  [RESOLVING]: s__('Captcha|Verifying'),
  [DONE]: s__('Captcha|Verification finished'),
};
