var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('RecycleScroller',_vm._g(_vm._b({ref:"scroller",attrs:{"items":_vm.itemsWithSize,"min-item-size":_vm.minItemSize,"direction":_vm.direction,"key-field":"id"},on:{"resize":_vm.onScrollerResize,"visible":_vm.onScrollerVisible},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var itemWithSize = ref.item;
var index = ref.index;
var active = ref.active;
return [_vm._t("default",null,null,{
        item: itemWithSize.item,
        index: index,
        active: active,
        itemWithSize: itemWithSize
      })]}},{key:"before",fn:function(){return [_vm._t("before")]},proxy:true},{key:"after",fn:function(){return [_vm._t("after")]},proxy:true}],null,true)},'RecycleScroller',_vm.$attrs,false),_vm.listeners))}
var staticRenderFns = []

export { render, staticRenderFns }